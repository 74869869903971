//
//  Copyright 2021 DataStax, Inc. 
//  
//  Licensed under the Apache License, Version 2.0 (the "License");
//  you may not use this file except in compliance with the License.
//  You may obtain a copy of the License at
//  
//      http://www.apache.org/licenses/LICENSE-2.0
//  
//  Unless required by applicable law or agreed to in writing, software
//  distributed under the License is distributed on an "AS IS" BASIS,
//  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//  See the License for the specific language governing permissions and
//  limitations under the License.
//  
//

import store from 'vuex-store'

let palette = store.getters.palette

export const defaultConfig = {
  legend: {
    position: 'bottom',
    labels: {
      fontColor: palette.fontColor,
      fontFamily: 'sans-serif',
      fontSize: 14,
      padding: 20,
      usePointStyle: true,
    },
  },
  tooltips: {
    bodyFontSize: 14,
    bodyFontFamily: 'sans-serif',
  },
  responsive: true,
  maintainAspectRatio: false,
}

export const chartTypesMap = {
  pie: 'pie-chart',
  donut: 'donut-chart',
  bubble: 'bubble-chart',
  line: 'line-chart',
  'horizontal-bar': 'horizontal-bar-chart',
  'vertical-bar': 'vertical-bar-chart',
}

export const chartTypes = Object.keys(chartTypesMap)
