<!--
  Copyright 2021 DataStax, Inc. 
  
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at
  
      http://www.apache.org/licenses/LICENSE-2.0
  
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
  
 -->

<template>
  <div class="auth-layout">
    <div class="nav d-lg-none">
          <div  style="" >
              <svg height="100" viewBox="-50 -25 200 100" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.708 23.1263C9.89001 22.9032 9.22076 22.5314 8.73741 22.048C8.25407 21.5647 7.88226 20.8954 7.65918 20.0775C7.4361 19.2595 7.32455 18.2556 7.32455 17.0287V0H0V18.0325C0 25.7661 3.86677 29.6328 11.6003 29.6328H21.7506V23.4609H13.7568C12.5298 23.4609 11.5259 23.3493 10.708 23.1263Z" fill="white"/>
  <path d="M40.0804 24.0928H35.6931C34.429 24.0928 33.4995 23.7954 32.9418 23.2376C32.384 22.6799 32.0866 21.7504 32.0866 20.4863V7.84493H24.9108V20.4863C24.9108 22.0851 25.0967 23.4236 25.4313 24.5761C25.8031 25.7287 26.3236 26.6583 27.0672 27.4019C27.8108 28.1455 28.7404 28.7032 29.893 29.0378C31.0455 29.4096 32.4212 29.5583 33.9828 29.5583H47.2562V7.88211H40.0432V24.0928H40.0804Z" fill="white"/>
  <path d="M72.539 10.0387C71.7954 9.29505 70.8659 8.73734 69.7133 8.40272C68.5607 8.03091 67.185 7.88219 65.6234 7.88219H52.1641V29.6328H59.3771V13.3849H63.9131C65.1772 13.3849 66.0696 13.6823 66.6645 14.2401C67.2222 14.7978 67.5196 15.6901 67.5196 16.9542V29.6328H74.7698V16.9542C74.7698 15.3555 74.5839 14.017 74.2493 12.8644C73.8031 11.7118 73.2826 10.7823 72.539 10.0387Z" fill="white"/>
  <path d="M100.052 12.8644C99.6802 11.7118 99.1597 10.7823 98.4161 10.0387C97.6725 9.29505 96.743 8.73734 95.5904 8.40272C94.4378 8.03091 93.0621 7.88219 91.5005 7.88219H79.7143V13.2362H91.0916C91.9095 13.2362 92.4672 13.4221 92.839 13.7567C93.1737 14.0913 93.3596 14.6862 93.3596 15.467V15.7273H85.1427C82.8747 15.7273 81.1644 16.3222 79.9746 17.5119C78.7848 18.7017 78.1899 20.4492 78.1899 22.68C78.1899 24.9108 78.7848 26.6212 79.9746 27.8481C81.1644 29.0751 82.9119 29.6328 85.1427 29.6328H100.573V16.9542C100.573 15.3555 100.387 14.017 100.052 12.8644ZM93.4339 24.3531H87.1876C86.6671 24.3531 86.2209 24.2044 85.9235 23.907C85.626 23.6095 85.4773 23.2005 85.4773 22.68C85.4773 22.1223 85.626 21.7133 85.9235 21.4159C86.2209 21.1184 86.6299 20.9697 87.1876 20.9697H93.4339V24.3531Z" fill="white"/>
  <path d="M4.49884 42.1627H3.0488C1.8962 42.1627 1.30132 41.5678 1.30132 40.4152C1.30132 39.8203 1.45004 39.4113 1.74748 39.1139C2.04493 38.8164 2.49109 38.7049 3.08598 38.7049H7.06429V37.5895H2.93726C1.97057 37.5895 1.26414 37.8125 0.74361 38.2959C0.260263 38.7792 0 39.4857 0 40.4524C0 41.4191 0.260263 42.1255 0.74361 42.5716C1.22696 43.055 1.97057 43.2781 2.93726 43.2781H4.35012C4.98218 43.2781 5.42835 43.4268 5.72579 43.7242C6.02324 44.0217 6.17196 44.4678 6.17196 45.0627C6.17196 45.6948 6.02324 46.141 5.72579 46.4384C5.42835 46.7359 4.945 46.8846 4.35012 46.8846H0.185902V48H4.49884C5.46553 48 6.20914 47.7397 6.72967 47.2564C7.25019 46.773 7.51046 46.0294 7.51046 45.0627C7.51046 44.096 7.25019 43.3524 6.72967 42.8691C6.20914 42.4229 5.46553 42.1627 4.49884 42.1627Z" fill="white"/>
  <path d="M10.3729 38.7049H13.9423V48H15.1692V38.7049H18.7014V37.5895H10.3729V38.7049Z" fill="white"/>
  <path d="M28.6656 43.3151C28.9631 43.1664 29.2233 42.9805 29.4092 42.7574C29.6323 42.4972 29.781 42.1997 29.8926 41.8651C30.0041 41.4933 30.0785 41.0843 30.0785 40.601C30.0785 40.1176 30.0041 39.6715 29.8554 39.2996C29.7067 38.9278 29.5208 38.6304 29.2605 38.3701C29.0002 38.1099 28.6656 37.924 28.2938 37.8124C27.922 37.7009 27.4758 37.6265 26.9925 37.6265H22.2334V47.9999H23.4975V43.6869H26.2489L29.1118 47.9999H30.6362L27.6989 43.6126C28.0707 43.5754 28.3682 43.4639 28.6656 43.3151ZM23.4975 42.5344V38.7048H26.8438C27.4758 38.7048 27.9592 38.8535 28.2938 39.1881C28.6284 39.4856 28.7772 39.9689 28.7772 40.6381C28.7772 41.9023 28.1451 42.5344 26.8438 42.5344H23.4975Z" fill="white"/>
  <path d="M37.3289 46.6615C36.8455 46.5128 36.4737 46.2897 36.1763 45.9922C35.8788 45.6948 35.6557 45.323 35.5442 44.8768C35.3955 44.4307 35.3211 43.9101 35.2839 43.2781H41.27V42.1627H35.3211C35.3583 41.5678 35.4327 41.0844 35.5814 40.6383C35.7301 40.1921 35.9532 39.8575 36.2134 39.56C36.5109 39.2626 36.8827 39.0767 37.3289 38.928C37.775 38.7792 38.3699 38.7049 39.0392 38.7049H41.3815V37.5895H38.7789C37.9609 37.5895 37.2173 37.701 36.6224 37.8869C36.0275 38.11 35.507 38.4074 35.1352 38.8536C34.7262 39.2998 34.4288 39.8203 34.2429 40.4895C34.057 41.1588 33.9454 41.9024 33.9454 42.7947C33.9454 43.6871 34.057 44.4679 34.2429 45.0999C34.4288 45.7692 34.7262 46.2897 35.1352 46.7359C35.5442 47.182 36.0275 47.4795 36.6596 47.7026C37.2545 47.9256 37.9981 48 38.8161 48H41.5674V46.8846H39.0763C38.3699 46.8846 37.8122 46.8102 37.3289 46.6615Z" fill="white"/>
  <path d="M48.4464 37.5895L44.4309 47.9628H45.7694L46.7733 45.2114H51.5696L52.5734 47.9628H53.9491L49.9336 37.5895H48.4464ZM47.1451 44.1332L49.1528 38.7421L51.1606 44.1332H47.1451Z" fill="white"/>
  <path d="M62.8346 46.6615L59.4512 37.5895H57.4062V48H58.596V38.7792L62.0538 48H63.541L66.9988 38.7792V48H68.263V37.5895H66.218L62.8346 46.6615Z" fill="white"/>
  <path d="M74.3615 37.5895H73.0974V47.9628H74.3615V37.5895Z" fill="white"/>
  <path d="M86.4074 46.2525L80.756 37.5895H79.2316V48H80.4585V39.2626L86.1471 48H87.5972V37.5895H86.4074V46.2525Z" fill="white"/>
  <path d="M96.223 42.1627V43.2781H99.309V46.8846H96.8922C96.223 46.8846 95.6281 46.8102 95.1447 46.6615C94.6614 46.5128 94.2896 46.2525 93.9922 45.9179C93.6947 45.5833 93.4716 45.1743 93.3601 44.6538C93.2114 44.1332 93.1742 43.5383 93.1742 42.7947C93.1742 42.0883 93.2486 41.4562 93.3601 40.9357C93.5088 40.4152 93.6947 40.0062 93.9922 39.6716C94.2896 39.3369 94.6614 39.1139 95.1447 38.928C95.6281 38.7792 96.1858 38.7049 96.8922 38.7049H100.164V37.5895H96.632C94.996 37.5895 93.8063 37.9984 93.0255 38.8536C92.2447 39.7087 91.8357 41.0101 91.8357 42.7947C91.8357 43.6871 91.9472 44.4679 92.1331 45.0999C92.319 45.7692 92.6165 46.2897 93.0255 46.7359C93.4344 47.182 93.9178 47.4795 94.5127 47.7026C95.1076 47.9256 95.814 48 96.632 48H100.573V42.1627H96.223V42.1627Z" fill="white"/>
  </svg>
          </div>
    </div>
    <div class="main va-row">
      <div class="auth-content flex lg6 xs12 fill-height">
        <div class="flex-center">
          <router-view/>
        </div>
      </div>
      <div class="auth-layout__auth-wallpaper flex md6">
        <div class="flex-center">
          <div class="auth-layout__auth-wallpaper__cross-line"/>
          <router-link class="auth-layout__auth-wallpaper__logo" :to="{path: '/'}">
            <luna-streaming-icon style="margin-top: -80px;"></luna-streaming-icon>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PulsarIcon from '../icon/PulsarIcon'
import LunaStreamingIcon from '../icon/LunaStreamingIcon'


export default {
  name: 'AuthLayout',
  components: { PulsarIcon, LunaStreamingIcon },
}
</script>

<style lang="scss" scoped>
.auth-layout {
  height: 100vh;
  margin: 0;

  &__auth-wallpaper {
    position: relative;
    background-color: $top-nav-bg;
    overflow: hidden;
    @include va-flex-center();
    &__logo {
      z-index: 2;
      position: absolute;
      height: $auth-wallpaper-ivuestic-h;
      width: 100%;
    }
    .vuestic-icon-vuestic {
      height: $auth-wallpaper-ivuestic-h;
      width: 100%;
    }

    &__cross-line {
      z-index: 1;
      position: absolute;
      background-color: $auth-wallpaper-oblique-line;
      left: calc(50% - 27% / 2);
      transform: rotate(15deg);
      width: 27%;
      height: 115%;
    }
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $top-mobile-nav-height;
    background-color: $top-nav-bg;
    .vuestic-icon-vuestic {
      height: $auth-mobile-nav-ivuestic-h;
      width: 100%;
    }
  }
  .main {
    margin: 0;
    height: 100vh;
    .auth-content {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
    }
  }

  @include media-breakpoint-down(md) {
    .main {
      height: $auth-mobile-main-h;
      .auth-content {
        align-items: flex-start;
        padding-top: $auth-content-padding-t;
      }
      .auth-wallpaper {
        display: none;
      }
    }
  }
}
</style>
